@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .banner{
  background-position-x: 120px ;
  background-position-y: 500px;
  animation: moveBackground 40s linear infinite;
  transition: .2s all linear;
 
}
@keyframes moveBackground {
  0% {
    background-position-x: 0 0 !important;
  }

  50% {
    background-position: 280px 0 !important;
  }
        100% {
          background-position-x: 0 0 !important;
        }
} */