

.afro{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0,0, 0.7)), url('./assets/Img/launch.jpg');
    background-size: cover;
    background-position: center;
}
.review{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0,0, 0.7)), url('./assets/Img/bunch.jpg');
    background-size: cover;
    background-position-x: bottom;
    background-position-y: bottom;
}
.back{
    background: url('./assets/Img/Untitled\ design.png');
    background-position: top;
    background-size: 2000px;
}
.pop-outin {
    animation:2s anim-popoutin ease infinite;
    transition: 0.1s all linear;
}
.no_scroll::-webkit-scrollbar{
  display: none;
}
.card p {
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    color: #666;
  }
  
  .card p.small {
    font-size: 14px;
  }
  
  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #345985;
    border-radius: 0 4px 0 32px;
  }
  
  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
  
  .card1 {
    display: block;
    position: relative;
    max-width: 282px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  }
  .card1::-webkit-scrollbar{
    display: none;
  }
  
  .card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #345985;
    height: 80px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }
  
  .card1:hover:before {
    transform: scale(21);
  }
  
  .card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
  }
  
  .card2 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
  }
  
  .card2:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #ccc;
    background-color: white;
  }
  
  .card2:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #345985;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }
  
  .card2:hover:before {
    transform: scale(2.15);
  }
  
  .card3 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #f2f8f9;
  }
  
  .card3 .go-corner {
    opacity: 0.7;
  }
  
  .card3:hover {
    border: 1px solid #345985;
    box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    z-index: 500;
  }
  
  .card3:hover p {
    color: #345985;
  }
  
  .card3:hover .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
  }
  
  .card4 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #fff;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  
  .card4 .go-corner {
    background-color: #345985;
    height: 100%;
    width: 16px;
    padding-right: 9px;
    border-radius: 0;
    transform: skew(6deg);
    margin-right: -36px;
    align-items: start;
    /* background-image: linear-gradient(-45deg, #8f479a 1%, #dc2a74 100%); */
  }
  
  .card4 .go-arrow {
    transform: skew(-6deg);
    margin-left: -2px;
    margin-top: 9px;
    opacity: 0;
  }
  
  .card4:hover {
    border: 1px solid #cd3d73;
  }
  
  .card4 h3 {
    margin-top: 8px;
  }
  
  .card4:hover .go-corner {
    margin-right: -12px;
  }
  
  .card4:hover .go-arrow {
    opacity: 1;
  }

@keyframes anim-popoutin {
    0% {
        color: black;
        transform: scale(0);
        opacity: 0;
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
        /* color:#f7f5f5; */
    }

    25% {
        color: red;
        transform: scale(1.1);
        opacity: 1;
        text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }

    50% {
        color: black;
        transform: scale(1);
        opacity: 1;
        text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        /* animate nothing to add pause at the end of animation */
        transform: scale(1);
        opacity: 1;
        text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
}

.fromtop {
    animation:1s anim-fromtop linear infinite;
}

@keyframes anim-fromtop {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    25% {
        opacity: 1;
        transform: translateY(0%);
    }

    50% {}

    100% {}
}

.button-icon {
    display: flex;
    border: 3px #fff solid;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
  }
  
  .icon {
    background-color: #fff;
    padding: 10px 10px 5px 10px;
  }
  
  .icon svg {
    width: 25px;
    height: 25px;
  }
  
  .cube {
    transition: all 0.4s;
    transform-style: preserve-3d;
    width: 200px;
    height: 20px;
  }
  
  .button-icon:hover {
    border-color: #009872;
  }
  
  .button-icon:hover .cube {
    transform: rotateX(90deg);
  }
  
  .side {
    position: absolute;
    height: 47px;
    width: 200px;
    display: flex;
    font-size: 0.8em;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
  }
  
  .top {
    background: #009872;
    color: #fff;
    transform: rotateX(-90deg) translate3d(0, 13.5px, 2em);
  }
  
  .front {
    background: #222229;
    color: #fff;
    transform: translate3d(0, 0, 1em);
  }

  .paystack-button{
    background-color: #009872;
    border: none;
      padding: 20px;
     font-family: montserrat;
      font-weight: bold;
      width: 100%;
      color:#fff;
      margin-top: 20px;
    }
    .paystack-button-disabled{
      background-color: #d7d7d7;
      cursor: not-allowed;
      padding: 20px;
      font-family: montserrat;
      font-weight: bold;
      width: 100%;
      border: none;
      color:#9f9f9f;
      margin-top: 20px;
    }
  

    #calendly-wrapper {
      position: fixed !important;
      bottom: 20px !important; /* Adjust as needed */
      right: 20px; /* Adjust as needed */
    }
